<template>




    <!-- Main Navigation -->
    <nav class="navbar navbar-expand-md main-nav navigation fixed-top sidebar-left">
    <div class="container">
    <button class="navbar-toggler" type="button">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
    </button>

    <a class="navbar-brand">
        
            <img src="../assets/img/logonobg.png" alt="Laapp" class="logo logo-sticky d-block d-md-none">
            <img src="../assets/img/logonobg.png" alt="Laapp" class="logo d-none d-md-block">
        
    </a>

    <div class="collapse navbar-collapse" id="main-navbar">
        <div class="sidebar-brand">
            <a>
                <img src="../assets/img/logonobg.png" alt="Laapp Template" class="logo">
            </a>
        </div>

        <ul class="nav navbar-nav ml-auto">
            <li class="nav-item" @click="scrollToSection('features')" style="cursor: pointer;"><a class="nav-link">Naše Usluge</a></li>
            <li class="nav-item" @click="scrollToSection('kontakt')" style="cursor: pointer;"><a class="nav-link">Kontakt</a></li>
        </ul>
    </div>
</div>

</nav>



<main >
    
    <!-- Alternative 1 Heading -->
<header class="header alter1-header section text-contrast" id="home">
    <div class="shapes-container">
        
        
            <div class="shape shape-animated" data-aos="fade-down-left" data-aos-duration="1500" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="fade-up-right" data-aos-duration="1000" data-aos-delay="200"></div>
        
            <div class="shape shape-animated" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200"></div>
        
            <div class="shape shape-animated" data-aos="fade-down-left" data-aos-duration="1000" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="fade-down-left" data-aos-duration="1000" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="300"></div>
        
            <div class="shape shape-animated" data-aos="fade-down-right" data-aos-duration="500" data-aos-delay="200"></div>
        
            <div class="shape shape-animated" data-aos="fade-down-right" data-aos-duration="500" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="zoom-out" data-aos-duration="2000" data-aos-delay="500"></div>
        
            <div class="shape shape-animated" data-aos="fade-up-right" data-aos-duration="500" data-aos-delay="200"></div>
        
            <div class="shape shape-animated" data-aos="fade-down-left" data-aos-duration="500" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0"></div>
        
            <div class="shape shape-animated" data-aos="fade-down" data-aos-duration="500" data-aos-delay="0"></div>
        
            <div class="shape shape-animated" data-aos="fade-up-right" data-aos-duration="500" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="fade-down-left" data-aos-duration="500" data-aos-delay="0"></div>
        
    </div>

    <div class="container">
        <div class="row">
            <div class="col-md-6">
          
                <h1 class="text-contrast display-4 display-md-3"><span class="bold">Terra Innovatis</span></h1>
                <p class="lead bold">Unaprijeđujemo vašu uslugu kroz digitalizaciju</p>

                <p class="lead">Istražite naša rješenja:</p>

                <nav class="nav my-5">

                    <a  class="btn btn-rounded btn-contrast" @click="scrollToSection('features')" style="color:black;" >Istraži</a>
                </nav>
            </div>

            <div class="col-md-6">
                <div class="iphone front mx-auto">
                    <div class="screen shadow-box">
                        <img src="../assets/img/screens/app/9.png" alt="...">
                    </div>
                    <div class="notch"></div>
                </div>
            </div>
        </div>
    </div>
</header>

    <!-- Features -->
<section class="section mt-6n" id="features">
    <div class="container pt-0">
        <div class="bg-contrast shadow rounded p-5">
            <div class="row gap-y">
                

                
                    <div class="col-md-4">
                        <div class="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
                            <i class="icon m-0 fa fa-exclamation pe-3x"></i>
                        </div>
                        <h4 class="semi-bold mt-4 text-capitalize">Prijava Kvarova</h4>

                        <hr class="w-25 bw-2 border-alternate mt-3 mb-4">
                        <p class="regular text-muted">
                            Umjesto prijave kvara putem recepcije ili e-maila, gosti sada mogu izravno i trenutno prijaviti kvar putem mobilne aplikacije. Čim gosti naprave zadatak, on dolazi točno određenom osoblju. Na taj način smo ubrzali učinkovitost rješavanja kvarova i odgovora na zahtjeve gostiju.
</p>
                    </div>
                
                    <div class="col-md-4">
                        <div class="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
                            <i class="icon m-0 fa fa-futbol pe-3x"></i>
                        </div>
                        <h4 class="semi-bold mt-4 text-capitalize">Rezervacije</h4>

                        <hr class="w-25 bw-2 border-alternate mt-3 mb-4">
                        <p class="regular text-muted">Gosti imaju priliku pregledati dostupne opcije i odabrati termine koji najbolje odgovaraju njihovom rasporedu i interesima. Ova funkcija ne samo da povećava zadovoljstvo gostiju, već i potiče goste na dadodatne aktivnosti koje će učiniti njihov boravak nezaboravnima.
for events.</p>
                    </div>
                
                    <div class="col-md-4">
                        <div class="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
                            <i class="icon m-0 fa fa-utensils pe-3x"></i>
                        </div>
                        <h4 class="semi-bold mt-4 text-capitalize">Dostave</h4>

                        <hr class="w-25 bw-2 border-alternate mt-3 mb-4">
                        <p class="regular text-muted">Umjesto da gosti gube vrijeme na čekanju u redovima ili tražeći restorane unutar kampa, sada mogu uživati u jednostavnom i brzom naručivanju hrane putem aplikacije. 
                            Ne samo da štede vrijeme, već im pruža visoku razinu udobnosti.
                        </p>
                    </div>

                    <div class="col-md-4">
                        <div class="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
                            <i class="icon m-0 fa fa-comment pe-3x"></i>
                        </div>
                        <h4 class="semi-bold mt-4 text-capitalize">Komunikacija</h4>

                        <hr class="w-25 bw-2 border-alternate mt-3 mb-4">
                        <p class="regular text-muted">
                            U cilju rješavanja izazova u komunikaciji s gostima, razvili smo tri ključne funkcije unutar naše aplikacije koje omogućavaju brzu, učinkovitu i personaliziranu interakciju.
                            Kroz live chat i organizirano slanje obavijesiti osigurali smo da gostu promakne što manje informacija.
                        </p>
                    </div>
                    <div class="col-md-4">
                        <div class="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
                            <i class="icon m-0 fa fa-info pe-3x"></i>
                        </div>
                        <h4 class="semi-bold mt-4 text-capitalize">Digitalna oglasna ploča</h4>

                        <hr class="w-25 bw-2 border-alternate mt-3 mb-4">
                        <p class="regular text-muted">
                            mogućava brzo ažuriranje informacija koje su bitne za goste. Na ovoj platformi možemo lako dijeliti najnovije informacije o događanjima, radnom vremenu, posebnim ponudama i drugim relevantnim detaljima. 
                        </p>
                    </div>

                    <div class="col-md-4">
                        <div class="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
                            <i class="icon m-0 fa fa-face-smile pe-3x"></i>
                        </div>
                        <h4 class="semi-bold mt-4 text-capitalize">Aktivnosti</h4>

                        <hr class="w-25 bw-2 border-alternate mt-3 mb-4">
                        <p class="regular text-muted">
                            Odjel aktivnosti predstavlja ključnu komponentu naše aplikacije, pružajući gostima sveobuhvatan pregled raznih ponuda u okolici kako bi maksimalno iskoristili svoj boravak, ne samo unutar kampa, već i izvan njega.
        

</p>
                    </div>

                 
                
            </div>
        </div>
    </div>
</section>

    <!-- Section Proposal -->
<section class="section our-proposal">
    <div class="shapes-container">
        <div class="shape shape-circle pattern-dots" data-aos="fade-up" data-aos-delay="200"></div>
        <div class="shape shape-circle shape-circle-fill" data-aos="fade-up"></div>

        <div class="pattern pattern-dots"></div>
    </div>

    <div class="container bring-to-front">
        <div class="row align-items-center">
            <div class="col-md-6 order-md-last">
                <figure class="mockup" style="width: 920px; max-width: 100%;">
                    <img src="../assets/img/zena.png" class="img-responsive" alt="...">
                </figure>
            </div>

            <div class="col-md-6">
                <h2 class="heading-line">Naš cilj</h2>
                <p class="lead text-muted">
                    Naša strast prema tehnologiji proizlazi iz dubokog uvjerenja da digitalizacija može transformirati iskustvo gostiju u kampovima.
                    Želimo podijeliti svoje bogato iskustvo kako bismo pružili rješenja koja će kampovima omogućiti suočavanje s različitim izazovima i omogućiti praćenje suvremenih trendova.
                    <br> Prilagodljivost  aplikacije ključna je za postizanje individualnih potreba i identiteta svakog kampa. Naš cilj je pružiti personalizirano iskustvo, počevši od loga i imena aplikacije, pa sve do informacija, slika i ostalih elemenata.

                </p>
            </div>
        </div>
    </div>
</section>


    <!-- Powerful Features -->
<section class="section alter3-features" id="features">
    <div class="shapes-container">
        <div class="shape shape-ring shape-ring-1">
            <div class="animation animation--rotating"></div>
        </div>
        <div class="shape shape-ring shape-ring-2">
            <div class="animation animation--rotating"></div>
        </div>
        <div class="shape shape-circle animation--clockwise">
            <div></div>
        </div>

        <div class="shape background-shape-main"></div>
    </div>

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 order-lg-last">
                <div class="section-heading">
                    <p class="px-2 text-alternate text-uppercase bold">Naša rješenja</p>
                    <h2 class="heading-line">2 Aplikacije za sve</h2>

                    <p class="lead text-muted my-4 semi-bold"></p>

                    <p>Naša Staff i Guest aplikacija predstavljaju jedinstveni ekosustav koji povezuje osoblje kampa i goste. </p>

                </div>
            </div>

            <div class="col-lg-7 pr-lg-6">
                <div class="row">
                    <div class="col-lg-6 rotated-cards">
                        
                        
    <div data-aos="fade-down" data-aos-delay="0">
        <div class="card border-0 shadow mb-5 tilt">
            <div class="card-body py-5 px-4">
                <div class="icon-box rounded-circle gradient gradient-primary-light text-contrast shadow icon-xl mb-3">
                    <i class="icon m-0 pe fa fa-user pe-3x"></i>
                </div>

                <h4 class="bold mb-5">Guest Aplikacija</h4>

                <p class="text-muted lead">Prijava Kvarova<br>Dostave Hrane<br>Live chat s recepcijom<br>Pregled aktivnosti u okolici<br>Digitalna Oglasna ploča<br>Samostalne rezervacije<br>Pregled suvenira</p>
            </div>
        </div>
    </div>


                    </div>

                    <div class="col-lg-6 rotated-cards">
                        
                        
    <div data-aos="fade-down" data-aos-delay="800">
        <div class="card border-0 shadow mb-5 tilt">
            <div class="card-body py-5 px-4">
                <div class="icon-box rounded-circle gradient gradient-primary-light text-contrast shadow icon-xl mb-3">
                    <i class="icon m-0 fa fa-users pe-3x"></i>
                </div>

                <h4 class="bold mb-5">Staff Aplikacija
</h4>

                <p class="text-muted lead">
                    Organizacija zadataka<br>
                    Grupne obavijesti za djelatnike<br>
                    Pregled rezervacija <br>
                    Live Chat sa gostom<br>
                    Organizirano slanje obavijesti<br>
                    Uređivanje svih podataka koje gosti vide<br>
                    Organiziran sustav za dostavu hrane<br>
                    Izgubljeno/Pronađeno<br>
          
                    

                </p>
            </div>
        </div>
    </div>



                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<br>
    <!-- Integrations -->


    <!-- Why Choose Us -->
<section class="section why-choose-us">
    <div class="shapes-container">
        <div class="pattern pattern-dots"></div>
    </div>

    <div class="container pb-8 bring-to-front">
      

        <div class="row gap-y">
            <div class="col-md-6">
                <h4 class="bold text-alternate">Naš učinak </h4>
                <p class="text-muted lead mb-5">Svjedočimo mnogim prednostima našeg sustava, samo neki od primjera su: </p>

                <ul class="list-unstyled why-icon-list">
                    
                    
                        <li class="list-item">
                            <div class="media align-items-center">
                                <div class="icon-shape mr-3">
                                    <div class="shape shape-pipes"></div>
                                    <i class="icon text-alternate fa fa-smile fa-3x"></i>
                                </div>

                                <div class="media-body">
                                    <h5 class="bold">Zadovoljstvo gostiju</h5>
                                    <p class="my-0">Unaprijediti zadovoljstvo gostiju bio je naš primarni cilj. Gosti imaju pristup svim aktivnostima unutar kampa na svom pametnom uređaju.</p>
                                </div>
                            </div>
                        </li>
                    
                        <li class="list-item">
                            <div class="media align-items-center">
                                <div class="icon-shape mr-3">
                                    <div class="shape shape-pipes"></div>
                                    <i class="icon text-alternate fa fa-users fa-3x"></i>
                                </div>

                                <div class="media-body">
                                    <h5 class="bold">Olakšana organizacija</h5>
                                    <p class="my-0">Primjenili smo naše iskustvo kako bi olakšali radno okruženje osoblju i učinili ih efikasnijim. Tako stare načine vođenja rezervacija zamijenili smo našim sustavom.</p>
                                </div>
                            </div>
                        </li>
                    
                        <li class="list-item">
                            <div class="media align-items-center">
                                <div class="icon-shape mr-3">
                                    <div class="shape shape-pipes"></div>
                                    <i class="icon text-alternate fa fa-arrow-trend-up fa-3x"></i>
                                </div>

                                <div class="media-body">
                                    <h5 class="bold">Promet</h5>
                                    <p class="my-0">Gostima su kroz cijelu aplikaciju prezentirane mnoge usluge, rezultat? Potaknuta interakcije gostiju koja pridonosi postizanju ciljeva povećanja prometa unutar vašeg kampa.</p>
                                </div>
                            </div>
                        </li>

                        <li class="list-item">
                            <div class="media align-items-center">
                                <div class="icon-shape mr-3">
                                    <div class="shape shape-pipes"></div>
                                    <i class="icon text-alternate fa fa-comments fa-3x"></i>
                                </div>

                                <div class="media-body">
                                    <h5 class="bold">Brža komunikacija</h5>
                                    <p class="my-0">Na razne načine uspostavili smo bolju komunikaciju između gostiju i osoblja. Kroz live chat, organizirano slanje obavijesti i digitalnu oglasnu ploču. Na taj način smo osigurali da što manje informacija promakne gostu.</p>
                                </div>
                            </div>
                        </li>
                    
                </ul>

                <hr class="my-5">
              
            </div>

            <div class="col-md-6">
                <div class="rotated-mockups device-twin">
                    <div class="browser absolute shadow-lg">
                        <img src="../assets/img/tablet1.png" alt="...">
                    </div>

                    <div class="front iphone light">
                        <div class="screen">
                            <img src="../assets/img/mob2.png" alt="...">
                        </div>

                        <div class="notch"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

    



</main>


<section class="section integration-bubbles" style="padding-bottom:200px">
    <div class="container overflow-hidden">
        <div class="row">
            <div class="col-md-6">
                <div class="bubbles-wrapper">
                    <div class="animations m-0">
                        
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-md">
                                <img src="../assets/img/smile.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Happy Guest</span>
                            </div>
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xxl">
                                <img src="../assets/img/smile.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Happy Guest</span>
                            </div>
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-lg">
                                <img src="../assets/img/smile.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Happy Guest</span>
                            </div>
                        
                   
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-lg">
                                <img src="../assets/img/smile.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Happy Guest</span>
                            </div>
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-md">
                                <img src="../assets/img/smile.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Happy Guest</span>
                            </div>
                        
      
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xl">
                                <img src="../assets/img/smile.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Happy Guest</span>
                            </div>
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-md">
                                <img src="../assets/img/smile.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Happy Guest</span>
                            </div>
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xxl">
                                <img src="../assets/img/smile.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Happy Guest</span>
                            </div>
                        
             
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xl">
                                <img src="../assets/img/smile.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Happy Guest</span>
                            </div>
                        
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="section-heading">
                    <p class="text-alternate text-uppercase bold">Puno zadovoljnih gostiju</p>
                    <h2 class="heading-line">Naše Iskustvo</h2>
                    <p class="lead text-muted">
                        Zadovoljstvo gostiju stoji u središtu naše misije a povratne informacije potvrđuje da smo uspjeli.
                    </p>
                </div>

 
            </div>
        </div>
    </div>
</section>

    <!-- Footer -->
<footer class="site-footer section bg-dark text-contrast edge top-left" id="kontakt" style="background-image: linear-gradient(130deg, #e4ecf1 0, #2596be 70%, #2596be 10%);">
    <div class="container py-3">
        <div class="row gap-y text-center text-md-left">
            <div class="col-md-4 mr-auto">
                <img src="../assets/img/logonobg.png" alt="" class="logo">
                <p>Terra Innovatis</p>
                <p>We provide innovative solutions for businesses</p>
                <p>+385 994351095</p>
                <p>leonardfabris2@gmail.com</p>
            </div>

            
            <div class="col-md-2">
                <h6 class="py-2 small">Follow us</h6>

                <nav class="nav justify-content-around">
                    <a href="https://facebook.com/5studios.net" target="_blank" class="btn btn-circle btn-sm brand-facebook"><i class="fab fa-facebook"></i></a>
                    <a href="#" class="btn btn-circle btn-sm brand-twitter"><i class="fab fa-twitter"></i></a>
                    <a href="#" class="btn btn-circle btn-sm brand-instagram"><i class="fab fa-instagram"></i></a>
   
                </nav>
            </div>
        </div>

        <hr class="mt-5 op-5" />
        <div class="row small">
            <div class="col-md-4">
                <p class="mt-2 mb-0 text-center text-md-left">© 2023 Terra Innovatis All Rights Reserved</p>
            </div>
        </div>
    </div>
</footer>



</template>

<script>

export default {
  name: 'HomeView',
  methods:{
    scrollToSection(sectionId) {
      // Find the element with the specified ID
      const targetElement = document.getElementById(sectionId);

      // Check if the element exists
      if (targetElement) {
        // Scroll to the element using smooth behavior
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }

  }

}
</script>


<style>
  @import '../assets/css/all.css';
</style>

<style>
  @import '../assets/css/aos.css';
</style>

<style>
  @import '../assets/css/laapp.css';
</style>

<style>
  @import '../assets/css/swiper.css';
</style>

<style>
  @import '../assets/css/pe-icon-7-stroke.css';
</style>

<style>
  @import '../assets/css/odometer-theme-minimal.css';
</style>