<template>




    <!-- Main Navigation -->
    <nav class="navbar navbar-expand-md main-nav navigation fixed-top sidebar-left">
    <div class="container">
    <button class="navbar-toggler" type="button">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
    </button>

    <a class="navbar-brand">
        
            <img src="../assets/img/logonobg.png" alt="Laapp" class="logo logo-sticky d-block d-md-none">
            <img src="../assets/img/logonobg.png" alt="Laapp" class="logo d-none d-md-block">
        
    </a>

    <div class="collapse navbar-collapse" id="main-navbar">
        <div class="sidebar-brand">
            <a>
                <img src="../assets/img/logonobg.png" alt="Laapp Template" class="logo">
            </a>
        </div>

        <ul class="nav navbar-nav ml-auto">
            <li class="nav-item" @click="scrollToSection('features')" style="cursor: pointer;"><a class="nav-link">Our services</a></li>
            <li class="nav-item" @click="scrollToSection('kontakt')" style="cursor: pointer;"><a class="nav-link">Contact</a></li>
        </ul>
    </div>
</div>

</nav>



<main >
    
    <!-- Alternative 1 Heading -->
<header class="header alter1-header section text-contrast" id="home">
    <div class="shapes-container">
        
        
            <div class="shape shape-animated" data-aos="fade-down-left" data-aos-duration="1500" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="fade-up-right" data-aos-duration="1000" data-aos-delay="200"></div>
        
            <div class="shape shape-animated" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200"></div>
        
            <div class="shape shape-animated" data-aos="fade-down-left" data-aos-duration="1000" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="fade-down-left" data-aos-duration="1000" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="300"></div>
        
            <div class="shape shape-animated" data-aos="fade-down-right" data-aos-duration="500" data-aos-delay="200"></div>
        
            <div class="shape shape-animated" data-aos="fade-down-right" data-aos-duration="500" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="zoom-out" data-aos-duration="2000" data-aos-delay="500"></div>
        
            <div class="shape shape-animated" data-aos="fade-up-right" data-aos-duration="500" data-aos-delay="200"></div>
        
            <div class="shape shape-animated" data-aos="fade-down-left" data-aos-duration="500" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0"></div>
        
            <div class="shape shape-animated" data-aos="fade-down" data-aos-duration="500" data-aos-delay="0"></div>
        
            <div class="shape shape-animated" data-aos="fade-up-right" data-aos-duration="500" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="fade-down-left" data-aos-duration="500" data-aos-delay="0"></div>
        
    </div>

    <div class="container">
        <div class="row">
            <div class="col-md-6">
          
                <h1 class="text-contrast display-4 display-md-3"><span class="bold">Terra Innovatis</span></h1>
                <p class="lead bold">We enhance campsite service through digitization</p>

      

                <nav class="nav my-5">

                    <a  class="btn btn-rounded btn-contrast" @click="scrollToSection('features')" style="color:black;" >Explore our solutions</a>
                </nav>
            </div>

            <div class="col-md-6">
                <div class="iphone front mx-auto">
                    <div class="screen shadow-box">
                        <img src="../assets/img/screens/app/9.png" alt="...">
                    </div>
                    <div class="notch"></div>
                </div>
            </div>
        </div>
    </div>
</header>

    <!-- Features -->
<section class="section mt-6n" id="features">
    <div class="container pt-0">
        <div class="bg-contrast shadow rounded p-5">
            <div class="row gap-y">
                

                
                    <div class="col-md-4">
                        <div class="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
                            <i class="icon m-0 fa fa-exclamation pe-3x"></i>
                        </div>
                        <h4 class="semi-bold mt-4 text-capitalize">Task Reporting</h4>

                        <hr class="w-25 bw-2 border-alternate mt-3 mb-4">
                        <p class="regular text-muted">
                            Instead of reporting issues through the reception or email, guests can now instantly and directly submit reports using the mobile app. Once a task is created by guests, it is automatically assigned to the relevant staff member. This has significantly accelerated the efficiency of resolving issues and responding to guest requests.
</p>
                    </div>
                
                    <div class="col-md-4">
                        <div class="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
                            <i class="icon m-0 fa fa-futbol pe-3x"></i>
                        </div>
                        <h4 class="semi-bold mt-4 text-capitalize">Reservations</h4>

                        <hr class="w-25 bw-2 border-alternate mt-3 mb-4">
                        <p class="regular text-muted">Guests have the opportunity to review available options and select schedules that best suit their preferences and schedule. This feature not only enhances guest satisfaction but also encourages guests to engage in additional activities, making their stay truly memorable.</p>
                    </div>
                
                    <div class="col-md-4">
                        <div class="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
                            <i class="icon m-0 fa fa-utensils pe-3x"></i>
                        </div>
                        <h4 class="semi-bold mt-4 text-capitalize">Deliveries</h4>

                        <hr class="w-25 bw-2 border-alternate mt-3 mb-4">
                        <p class="regular text-muted">Instead of guests wasting time waiting in lines or searching for restaurants within the camp, they can now enjoy simple and quick food ordering through the app. Not only does this save time, but it also provides them with a high level of convenience since they can spend more time with their friends and family in comfort of their accommodation.
                        </p>
                    </div>

                    <div class="col-md-4">
                        <div class="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
                            <i class="icon m-0 fa fa-comment pe-3x"></i>
                        </div>
                        <h4 class="semi-bold mt-4 text-capitalize">Communication</h4>

                        <hr class="w-25 bw-2 border-alternate mt-3 mb-4">
                        <p class="regular text-muted">
                            To address challenges in guest communication, we have developed key features within our app to facilitate fast, efficient, and personalized interaction. Through live chat and organized notifications, we ensure that guests miss as little information as possible.
                        </p>
                    </div>
                    <div class="col-md-4">
                        <div class="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
                            <i class="icon m-0 fa fa-info pe-3x"></i>
                        </div>
                        <h4 class="semi-bold mt-4 text-capitalize">Digital information board</h4>

                        <hr class="w-25 bw-2 border-alternate mt-3 mb-4">
                        <p class="regular text-muted">
                            It enables quick updates of crucial information for guests. On this platform, we can easily share the latest details about events, operating hours, special offers, and other relevant information.
                        </p>
                    </div>

                    <div class="col-md-4">
                        <div class="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
                            <i class="icon m-0 fa fa-face-smile pe-3x"></i>
                        </div>
                        <h4 class="semi-bold mt-4 text-capitalize">Activities</h4>

                        <hr class="w-25 bw-2 border-alternate mt-3 mb-4">
                        <p class="regular text-muted">
                            The Activities section represents a key component of our app, providing guests with a comprehensive overview of various offerings in the vicinity, allowing them to make the most of their stay, not only within the camp but also beyond
        

</p>
                    </div>

                 
                
            </div>
        </div>
    </div>
</section>

    <!-- Section Proposal -->
<section class="section our-proposal">
    <div class="shapes-container">
        <div class="shape shape-circle pattern-dots" data-aos="fade-up" data-aos-delay="200"></div>
        <div class="shape shape-circle shape-circle-fill" data-aos="fade-up"></div>

        <div class="pattern pattern-dots"></div>
    </div>

    <div class="container bring-to-front">
        <div class="row align-items-center">
            <div class="col-md-6 order-md-last">
                <figure class="mockup" style="width: 920px; max-width: 100%;">
                    <img src="../assets/img/zena.png" class="img-responsive" alt="...">
                </figure>
            </div>

            <div class="col-md-6">
                <h2 class="heading-line">Our objective</h2>
                <p class="lead text-muted">
                    Our passion for technology stems from a deep belief that digitalization can transform the guest experience at campsites.
                    We want to share our rich experience in order to provide solutions that will enable camps to face different challenges and enable them to follow the trends.
                    <br> The adaptability of the application is key to achieving the individual needs and identity of each camp. Our goal is to provide a personalized experience, starting from the logo and name of the application, all the way to information, images and other elements.

                </p>
            </div>
        </div>
    </div>
</section>


    <!-- Powerful Features -->
<section class="section alter3-features" id="features">
    <div class="shapes-container">
        <div class="shape shape-ring shape-ring-1">
            <div class="animation animation--rotating"></div>
        </div>
        <div class="shape shape-ring shape-ring-2">
            <div class="animation animation--rotating"></div>
        </div>
        <div class="shape shape-circle animation--clockwise">
            <div></div>
        </div>

        <div class="shape background-shape-main"></div>
    </div>

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 order-lg-last">
                <div class="section-heading">
                    <p class="px-2 text-alternate text-uppercase bold">Our solutions</p>
                    <h2 class="heading-line">2 Apps for everything</h2>

                    <p class="lead text-muted my-4 semi-bold"></p>

                    <p>Our Staff and Guest applications represent a unique ecosystem that connects camp staff and guests.</p>

                </div>
            </div>

            <div class="col-lg-7 pr-lg-6">
                <div class="row">
                    <div class="col-lg-6 rotated-cards">
                        
                        
    <div data-aos="fade-down" data-aos-delay="0">
        <div class="card border-0 shadow mb-5 tilt">
            <div class="card-body py-5 px-4">
                <div class="icon-box rounded-circle gradient gradient-primary-light text-contrast shadow icon-xl mb-3">
                    <i class="icon m-0 pe fa fa-user pe-3x"></i>
                </div>

                <h4 class="bold mb-5">Guest App</h4>

                <p class="text-muted lead">Task Reporting<br>Deliveries<br>Live chat<br>Activities<br>Digital information board<br>Reservations<br>Souvenirs showcase ... </p>
            </div>
        </div>
    </div>


                    </div>

                    <div class="col-lg-6 rotated-cards">
                        
                        
    <div data-aos="fade-down" data-aos-delay="800">
        <div class="card border-0 shadow mb-5 tilt">
            <div class="card-body py-5 px-4">
                <div class="icon-box rounded-circle gradient gradient-primary-light text-contrast shadow icon-xl mb-3">
                    <i class="icon m-0 fa fa-users pe-3x"></i>
                </div>

                <h4 class="bold mb-5">Staff App
</h4>

                <p class="text-muted lead">
                    Tasks organization<br>
                    Overview of reservations <br>
                    Live Chat with a guest<br>
                    Organized sending of notifications<br>
                    Editing of all data that guests see<br>
                    Organized food delivery system<br>
                    Lost/Found ...<br>
          
                    

                </p>
            </div>
        </div>
    </div>



                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<br>
    <!-- Integrations -->


    <!-- Why Choose Us -->
<section class="section why-choose-us">
    <div class="shapes-container">
        <div class="pattern pattern-dots"></div>
    </div>

    <div class="container pb-8 bring-to-front">
      

        <div class="row gap-y">
            <div class="col-md-6">
                <h4 class="bold text-alternate">Our impact </h4>
                <p class="text-muted lead mb-5">We witness many advantages of our system, just some of the examples are: </p>

                <ul class="list-unstyled why-icon-list">
                    
                    
                        <li class="list-item">
                            <div class="media align-items-center">
                                <div class="icon-shape mr-3">
                                    <div class="shape shape-pipes"></div>
                                    <i class="icon text-alternate fa fa-smile fa-3x"></i>
                                </div>

                                <div class="media-body">
                                    <h5 class="bold">Enhanced Guest satisfaction</h5>
                                    <p class="my-0">Improving guest satisfaction was our primary goal. Guests have access to all activities within the camp on their smart device.</p>
                                </div>
                            </div>
                        </li>
                    
                        <li class="list-item">
                            <div class="media align-items-center">
                                <div class="icon-shape mr-3">
                                    <div class="shape shape-pipes"></div>
                                    <i class="icon text-alternate fa fa-users fa-3x"></i>
                                </div>

                                <div class="media-body">
                                    <h5 class="bold">More efficient staff and easier organization</h5>
                                    <p class="my-0">We applied our experience to facilitate the working environment for the staff and make them more efficient. We have replaced old ways of keeping reservations with our system.</p>
                                </div>
                            </div>
                        </li>
                    
                        <li class="list-item">
                            <div class="media align-items-center">
                                <div class="icon-shape mr-3">
                                    <div class="shape shape-pipes"></div>
                                    <i class="icon text-alternate fa fa-arrow-trend-up fa-3x"></i>
                                </div>

                                <div class="media-body">
                                    <h5 class="bold">Increased sales</h5>
                                    <p class="my-0">Many services were presented to the guests throughout the application, the result? Encouraged guest interactions that contribute to the goals of increasing traffic within your camp.</p>
                                </div>
                            </div>
                        </li>

                        <li class="list-item">
                            <div class="media align-items-center">
                                <div class="icon-shape mr-3">
                                    <div class="shape shape-pipes"></div>
                                    <i class="icon text-alternate fa fa-comments fa-3x"></i>
                                </div>

                                <div class="media-body">
                                    <h5 class="bold">Faster communication</h5>
                                    <p class="my-0">
We have established better communication between guests and staff in various ways. Through live chat, organized sending of notifications and digital information board. In this way, we ensured that as little information as possible was missed by the guest.</p>
                                </div>
                            </div>
                        </li>
                    
                </ul>

                <hr class="my-5">
              
            </div>

            <div class="col-md-6">
                <div class="rotated-mockups device-twin">
                    <div class="browser absolute shadow-lg">
                        <img src="../assets/img/tablet1.png" alt="...">
                    </div>

                    <div class="front iphone light">
                        <div class="screen">
                            <img src="../assets/img/mob2.png" alt="...">
                        </div>

                        <div class="notch"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

    



</main>


<section class="section integration-bubbles" style="padding-bottom:200px">
    <div class="container overflow-hidden">
        <div class="row">
            <div class="col-md-6">
                <div class="bubbles-wrapper">
                    <div class="animations m-0">
                        
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-md">
                                <img src="../assets/img/smile.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Happy Guest</span>
                            </div>
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xxl">
                                <img src="../assets/img/smile.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Happy Guest</span>
                            </div>
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-lg">
                                <img src="../assets/img/smile.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Happy Guest</span>
                            </div>
                        
                   
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-lg">
                                <img src="../assets/img/smile.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Happy Guest</span>
                            </div>
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-md">
                                <img src="../assets/img/smile.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Happy Guest</span>
                            </div>
                        
      
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xl">
                                <img src="../assets/img/smile.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Happy Guest</span>
                            </div>
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-md">
                                <img src="../assets/img/smile.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Happy Guest</span>
                            </div>
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xxl">
                                <img src="../assets/img/smile.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Happy Guest</span>
                            </div>
                        
             
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xl">
                                <img src="../assets/img/smile.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Happy Guest</span>
                            </div>
                        
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="section-heading">
                    <p class="text-alternate text-uppercase bold">Lots of satisfied guests</p>
                    <h2 class="heading-line">Our Experience</h2>
                    <p class="lead text-muted">
                        Guest satisfaction is at the center of our mission, and feedback confirms that we have succeeded.
                    </p>
                </div>

 
            </div>
        </div>
    </div>
</section>

    <!-- Footer -->
<footer class="site-footer section bg-dark text-contrast edge top-left" id="kontakt" style="background-image: linear-gradient(130deg, #e4ecf1 0, #2596be 70%, #2596be 10%);">
    <div class="container py-3">
        <div class="row gap-y text-center text-md-left">
            <div class="col-md-4 mr-auto">
                <img src="../assets/img/logonobg.png" alt="" class="logo">
                <p>Terra Innovatis</p>
                <p>We provide innovative solutions for businesses</p>
                <p>+385 994351095</p>
                <p>leonardfabris2@gmail.com</p>
            </div>

            
            <div class="col-md-2">
                <h6 class="py-2 small">Follow us</h6>

                <nav class="nav justify-content-around">
                    <a href="https://facebook.com/5studios.net" target="_blank" class="btn btn-circle btn-sm brand-facebook"><i class="fab fa-facebook"></i></a>
                    <a href="#" class="btn btn-circle btn-sm brand-twitter"><i class="fab fa-twitter"></i></a>
                    <a href="#" class="btn btn-circle btn-sm brand-instagram"><i class="fab fa-instagram"></i></a>
   
                </nav>
            </div>
        </div>

        <hr class="mt-5 op-5" />
        <div class="row small">
            <div class="col-md-4">
                <p class="mt-2 mb-0 text-center text-md-left">© 2023 Terra Innovatis All Rights Reserved</p>
            </div>
        </div>
    </div>
</footer>



</template>

<script>

export default {
  name: 'HomeView',
  methods:{
    scrollToSection(sectionId) {
      // Find the element with the specified ID
      const targetElement = document.getElementById(sectionId);

      // Check if the element exists
      if (targetElement) {
        // Scroll to the element using smooth behavior
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }

  }

}
</script>


<style>
  @import '../assets/css/all.css';
</style>

<style>
  @import '../assets/css/aos.css';
</style>

<style>
  @import '../assets/css/laapp.css';
</style>

<style>
  @import '../assets/css/swiper.css';
</style>

<style>
  @import '../assets/css/pe-icon-7-stroke.css';
</style>

<style>
  @import '../assets/css/odometer-theme-minimal.css';
</style>