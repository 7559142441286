<template>


</template>


<script>

export default {
  name: 'HomeView',
  mounted(){
    window.location.href = 'https://drive.google.com/file/d/1P3V9qWST0xjzKgB0NNbibkStErDmCL0e/view?usp=sharing'
  }


}
</script>


